import "./faq.css";
import {useEffect, useState} from "react";
import {NavLink} from "react-router-dom";

export const FAQ = () => {

    const [questions, setQuestions] = useState([]);

    useEffect(() => {
        fetch("https://strapi.waywardcookies.com/api/faqs")
            .then(response => response.json())
            .then(resp => setQuestions(resp.data))
            .catch(error => console.error("Error fetching data:", error));
        document.title = "Wayward Cookies : Frequently Asked Questions";
    }, []);

    return (
        <div className={"container py-5 text-center"}>
            {questions?.sort((a, b) => {
                const orderA = a.OrderIndex;
                const orderB = b.OrderIndex;
                if (orderA < orderB) return -1;
                if (orderA > orderB) return 1;
                return 0;
            })
                .map((question, index) => (
                    <div className={"row justify-content-center pb-5"} key={"faq" + index}>
                        <div className={"col-md-8"}>
                            <p className={"question"}>{question.QuestionText}</p>
                            <p className={"answer"}>
                                {question.AnswerText}
                            </p>
                        </div>
                    </div>
                ))
            }
            <div className={"row"}>
                <div className={"col"}>
                    See our&nbsp;
                    <NavLink to={"/cookie-care"}>cookie care guide</NavLink>
                    &nbsp;for more information.
                </div>
            </div>
        </div>
    )
}