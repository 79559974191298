import {NavLink, useLocation} from "react-router-dom";
import "./header.css";
import {useEffect} from "react";
import ReactGA from "react-ga4";

export const Header = () => {

    let location = useLocation();

    useEffect(() => {
        ReactGA.initialize(process.env.REACT_APP_GA_ID);
    }, []);

    useEffect(() => {
        ReactGA.send({hitType: "pageview", page: location.pathname});
    }, [location.pathname]);

    const handleActiveStyle = ({isActive, isPending}) => {
        const baseClass = "nav-link px-5 px-md-3 ";
        return isPending ? baseClass + "pending" : isActive ? baseClass + "active" : baseClass
    };

    return (
        <nav className="navbar navbar-expand-md" id={"header"}>
            <div className="container-fluid">
                <a className="navbar-brand d-block d-md-none flex-fill" href="/">
                    <img src={process.env.PUBLIC_URL + "/wayward-logo-text-large.png"} alt={"Wayward Cookies Logo"}
                         width={"100%"}/>
                </a>
                <a className="navbar-brand d-none d-md-block" href="/">
                    <img src={process.env.PUBLIC_URL + "/wayward-logo-face-200.png"} alt={"Wayward Cookies Logo"}
                         width={"100%"}/>
                </a>
                <button className="navbar-toggler me-4" type="button" data-bs-toggle="collapse"
                        data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
                        aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse justify-content-center" id="navbarSupportedContent">
                    <div className="navbar-nav mb-2 mb-lg-0">
                        <NavLink
                            to="/"
                            className={handleActiveStyle}
                        >
                            Home
                        </NavLink>
                        <NavLink
                            to="/gallery"
                            className={handleActiveStyle}
                        >
                            Gallery
                        </NavLink>
                        <NavLink
                            to="/shop"
                            className={handleActiveStyle}
                        >
                            Shop
                        </NavLink>
                        <NavLink
                            to="/custom-order"
                            className={handleActiveStyle}
                        >
                            Custom Order
                        </NavLink>
                        <NavLink
                            to="/faq"
                            className={handleActiveStyle}
                        >
                            FAQs
                        </NavLink>
                        <NavLink
                            to="/contact"
                            className={handleActiveStyle}
                        >
                            Contact
                        </NavLink>
                    </div>
                </div>
            </div>
        </nav>
    )
}