import "./home.css";
import {Fragment, useEffect, useState} from "react";
import {NavLink} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faQuoteLeft, faQuoteRight} from "@fortawesome/free-solid-svg-icons";
import {daysBetween, formatMonthDay} from "../../util/helpers";

export const Home = () => {

    const [callouts, setCallouts] = useState([]);
    const [testimonials, setTestimonials] = useState([]);
    const [events, setEvents] = useState([]);

    useEffect(() => {
            fetch("https://strapi.waywardcookies.com/api/home-callouts?populate=*")
                .then(response => response.json())
                .then(resp => setCallouts(resp.data))
                .catch(error => console.error("Error fetching data:", error));
            fetch("https://strapi.waywardcookies.com/api/testimonials?populate=*")
                .then(response => response.json())
                .then(resp => setTestimonials(resp.data))
                .catch(error => console.error("Error fetching data:", error));
            fetch("https://strapi.waywardcookies.com/api/events?populate=*")
                .then(response => response.json())
                .then(resp => setEvents(resp.data))
                .catch(error => console.error("Error fetching data:", error));
            document.title = "Wayward Cookies : Awesome Custom Cookies";
        },
        []);

    const formatDates = (event) => {
        const startDate = new Date(event.StartDate + "T00:00:00");
        let endDate = event.EndDate;
        if (endDate) {
            endDate = new Date(endDate + "T00:00:00");
        }

        if (!endDate) {
            return formatMonthDay(startDate);
        }
        if (startDate.getMonth() !== endDate.getMonth()) {
            return formatMonthDay(startDate) + " - " + formatMonthDay(endDate);
        }
        if (daysBetween(startDate, endDate) === 1) {
            return formatMonthDay(startDate) + " & " + endDate.getDate();
        }
        return formatMonthDay(startDate) + " - " + endDate.getDate();
    }

    return (
        <Fragment>
            <div className={"container-fluid"}>
                <div className="overflow-hidden d-none d-md-block" style={{maxHeight: "30vh"}}>
                    <div className="container px-5">
                        <img src={process.env.PUBLIC_URL + "/wayward-logo-text-xl-alt.png"}
                             alt={"Wayward Cookies Text Logo"}
                             width={"100%"}/>
                    </div>
                </div>
                <div className="overflow-hidden position-relative mt-5">
                    <div className="container-fluid main-hero" style={{height: "30vh"}}></div>
                </div>
            </div>
            <div className={"container my-5"}>
                {
                    callouts?.sort((a, b) => {
                        return a.Order - b.Order;
                    }).map((callout) => (
                        <div className={"row pb-5"} key={callout.CalloutTitle.replace(" ", "")}>
                            <div
                                className={"col-lg-5 d-flex align-content-center py-3 px-5 order-lg-2 " + (callout.Order % 2 ? "order-lg-2" : "order-lg-1")}>
                                <img
                                    src={`https://strapi.waywardcookies.com${callout.CalloutImage.formats.small.url}`}
                                    alt={callout.CalloutImage.alternativeText}
                                    style={{margin: "auto", border: "#655A4F solid 1px"}} width={"100%"}/>
                            </div>
                            <div
                                className={"col-md d-flex align-items-center px-5 " + (callout.Order % 2 ? "order-lg-1" : "order-lg-2")}>
                                <div>
                                    <h3 className={"article-title pb-2"}>{callout.CalloutTitle}</h3>
                                    <p className={"pb-3"}>
                                        {callout.CalloutText}
                                    </p>
                                    <p>
                                        <NavLink to={callout.ButtonURL}
                                                 className={"btn btn-primary"}>{callout.ButtonText}</NavLink>
                                    </p>
                                </div>
                            </div>
                        </div>
                    ))
                }
                <div className={"row pb-5"}>
                    <div className={"col-lg-5 d-flex order-lg-2 align-content-center py-3 px-5"}>
                        <img src={process.env.PUBLIC_URL + "/events-medium.jpg"} alt={"cats"}
                             style={{margin: "auto", border: "#655A4F solid 1px"}} width={"100%"}/>
                    </div>
                    <div className={"col-md d-flex align-items-center order-lg-1 px-5"}>
                        <div>
                            <h3 className={"article-title pb-3"}>Upcoming Events</h3>
                            <ul className={"pb-2"}>
                                {
                                    events
                                        .sort((a, b) => {
                                            const dateA = new Date(a.StartDate);
                                            const dateB = new Date(b.StartDate);
                                            return dateA - dateB;
                                        })
                                        .map(event => {
                                            if (event.EventURL) {
                                                return <li key={event.id}>
                                                    <a href={event.EventURL}
                                                       target={"_blank"}>{event.EventTitle}
                                                    </a>&nbsp;|&nbsp;{formatDates(event)}
                                                </li>
                                            } else {
                                                return <li
                                                    key={event.id}>{event.EventTitle}&nbsp;|&nbsp;{formatDates(event)}</li>
                                            }
                                        })
                                }
                            </ul>
                        </div>
                    </div>
                </div>
                <div className={"row pb-5"}>
                    {
                        testimonials?.sort((a, b) => {
                            return a.Order - b.Order;
                        }).map((testimonial) => (
                            <div className={"col-lg-4 d-flex align-content-center mb-5 px-4"}
                                 key={testimonial.ReviewerName.replace(" ", "")}>
                                <div>
                                    <p className={"text-center flex-grow-1 review-text"}>
                                        <FontAwesomeIcon icon={faQuoteLeft} className={"pe-1"}/>
                                        {testimonial.ReviewText[0].children[0].text}
                                        <FontAwesomeIcon icon={faQuoteRight} className={"ps-1"}/>
                                    </p>
                                    <h3 className={"reviewer-name text-end pe-4"}>- {testimonial.ReviewerName}</h3>
                                </div>
                            </div>
                        ))
                    }
                </div>
            </div>
        </Fragment>
    )
}
