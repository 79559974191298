export const getWaywardLoginHeaders = (username, password) => {
    const headers = new Headers();
    headers.append("Content-Type", "application/json");
    headers.append("Authorization", "BASIC " + tokenizeLogin(username, password));
    return headers;
};

export const getWaywardLoggedInHeaders = (token) => {
    const headers = new Headers();
    headers.append("Content-Type", "application/json");
    headers.append("Authorization", "BASIC " + token);
    return headers;
}

export const tokenizeLogin = (username, password) => {
    return window.btoa(username + ':' + password);
}

export const formatDateForRequest = (dateToFormat) => {
    if (/^\d+$/.test(dateToFormat)) {
        return dateToFormat < 1 ? null : new Date(dateToFormat).getTime();
    }
    return Date.parse(dateToFormat);
}