import PhotoAlbum from "react-photo-album";
import React, {useEffect, useRef, useState} from "react";
import "./gallery.css";

export const Gallery = () => {

    const modalRef = useRef(null);
    const [showModal, setShowModal] = useState(false);
    const [fullScreenPath, setFullScreenPath] = useState("");
    const [images, setImages] = useState([]);

    const formatData = (rawGalleryData) => {
        const formattedData = rawGalleryData.GalleryItems.map((rawRow, index) => {
            return {
                key: "pic-" + index,
                src: `https://strapi.waywardcookies.com${rawRow.formats.small.url}`,
                width: rawRow.formats.small.width,
                height: rawRow.formats.small.height,
                fullPath: rawRow.url,
                updatedAt: rawRow.updatedAt
            }
        });
        formattedData.sort(byDateUpdated);
        setImages(formattedData);
    }

    useEffect(() => {
        fetch("https://strapi.waywardcookies.com/api/gallery?populate=*")
            .then(response => response.json())
            .then(resp => formatData(resp.data))
            .catch(error => console.error("Error fetching data:", error));
        document.title = "Wayward Cookies : Gallery & Portfolio";
    }, []);

    const handleClose = () => {
        setShowModal(false);
    }

    useEffect(() => {
        if (showModal) {
            modalRef.current.addEventListener("click", handleClose);
        }
        return () => {
            if (modalRef.current) {
                modalRef.current.removeEventListener("click", handleClose);
            }
        };
    }, [showModal]);

    const clickThumbnail = (image) => {
        setFullScreenPath(image.photo.fullPath);
        setShowModal(true);
    }

    const byDateUpdated = (a, b) => {
        return new Date(b.updatedAt) - new Date(a.updatedAt);
    }

    return (
        <div className={"container py-5"}>
            <PhotoAlbum onClick={clickThumbnail} layout="masonry" photos={images}/>

            <div className={"modal modal-xl"} data-bs-backdrop="static" tabIndex="-1" id={"fullScreenImage"}
                 role="dialog" ref={modalRef} style={{display: showModal ? "block" : "none"}}>
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-body p-0">
                            {fullScreenPath &&
                                <img src={`https://strapi.waywardcookies.com${fullScreenPath}`} alt={"High Res Pic"}
                                     style={{width: "100%"}}/>
                            }
                        </div>
                    </div>
                </div>
                <div className={"modal-backdrop"}
                     style={{display: showModal ? "block" : "none"}}></div>
            </div>
        </div>
    )
}