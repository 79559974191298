import {useEffect, useState} from "react";

export const TermsAndConditions = () => {

    const [termsAndConditions, setTermsAndConditions] = useState({});

    useEffect(() => {
        fetch("https://strapi.waywardcookies.com/api/terms-and-condition?populate=*")
            .then(response => response.json())
            .then(resp => setTermsAndConditions(resp.data.attributes.Image.data.attributes.url))
            .catch(error => console.error("Error fetching data:", error));
        document.title = "Wayward Cookies : Terms & Conditions";
    }, []);

    return (
        <div className={"container py-5 text-center"}>
            <div className={"row"}>
                <div className={"col"}>
                    <img src={`https://strapi.waywardcookies.com${termsAndConditions}`}
                         alt={"Wayward Cookies Terms And Conditions"}
                         style={{width: "100%"}}/>
                </div>
            </div>
        </div>
    )
}